import React, { useEffect, useState } from 'react'
import Wafy from "./Wafy"
import Openflex from "./Openflex"
import lodash from "lodash"

enum Bee2Link
{
    "Wafy" = "Wafy",
    "OpenFlex" = "OpenFlex"
}

const Bee2LinkMappingID = {
    [Bee2Link.Wafy]: "ClientIDBee2Link",
    [Bee2Link.OpenFlex]: "EntityIDBee2Link",
}

const Bee2LinkMappingURL = {
    [Bee2Link.Wafy]: "bee2LinkWafy",
    [Bee2Link.OpenFlex]: "bee2LinkOpenflex",
}

export default function Form() {

    const [ actif, setActif ] = useState<Bee2Link>(Bee2Link.Wafy)

    const [ loaded, setLoaded ] = useState<boolean>(false)
    const [ saving, setSaving ] = useState<boolean>(false)
    const [ updated, setUpdated ] = useState<boolean>(false)
    const [ loadingSitesFromBee2Link, setLoadingSitesFromBee2Link ] = useState<boolean>(false)

    const [ sites, setSites ] = useState<any[]>([])
    const [ sitesBee2Link, setSitesBee2Link ] = useState<any[]>([])
    const [ sitesBee2LinkResult, setSitesBee2LinkResult ] = useState<any[]>([])

    useEffect(() => {
        fetchConfig(actif)
    }, [ actif ])

    const fetchConfig = (actif: Bee2Link) => {
        fetch(localStorage.backoffice_api + `/api/interfaces/${Bee2LinkMappingURL[actif]}/config`, {
            method: 'GET',
            headers: {
                authorization: localStorage.JWT,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText)
                }
                return response.json()
            })
            .then((json) => {
                setLoaded(true)
                setSites(json.sites)
                return json.sites
            })
            .then((sites) => {
                for (const i in sites) {
                    if (sites[i][Bee2LinkMappingID[actif]])
                        fetchSitesBee2Link(sites[i][Bee2LinkMappingID[actif]])
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const loadSitesBee2Link = (IDBee2Link: any) => {
        if (IDBee2Link === null || IDBee2Link === "")
            return
        else if (!sitesBee2LinkResult[IDBee2Link])
            fetchSitesBee2Link(IDBee2Link)
    }

    const fetchSitesBee2Link = (IDBee2Link: any) => {
        const sitesBee2LinkResultToUpdate = lodash.cloneDeep(sitesBee2LinkResult)
        sitesBee2LinkResultToUpdate[IDBee2Link] = 'loading'

        setSitesBee2LinkResult(sitesBee2LinkResultToUpdate)
        setLoadingSitesFromBee2Link(true)

        fetch(localStorage.backoffice_api + `/api/interfaces/${Bee2LinkMappingURL[actif]}/getSitesBee2link?IdClient=` + IDBee2Link, {
            method: 'GET',
            headers: {
                authorization: localStorage.JWT,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText)
                }
                return response.json()
            })
            .then((json) => {
                const sitesBee2LinkToUpdate = lodash.cloneDeep(sitesBee2Link)
                sitesBee2LinkToUpdate[IDBee2Link] = json.sites
                sitesBee2LinkResultToUpdate[IDBee2Link] = 'ok'

                setSitesBee2Link(sitesBee2LinkToUpdate)
                setSitesBee2LinkResult(sitesBee2LinkResultToUpdate)
                setLoadingSitesFromBee2Link(false)

            })
            .catch((error) => {
                const sitesBee2LinkToUpdate = lodash.cloneDeep(sitesBee2Link)
                sitesBee2Link[IDBee2Link] = []
                sitesBee2LinkResultToUpdate[IDBee2Link] = 'ko'

                setSitesBee2Link(sitesBee2LinkToUpdate)
                setSitesBee2LinkResult(sitesBee2LinkResultToUpdate)
                setLoadingSitesFromBee2Link(false)

            })
    }

    const submitConfig = (event: any) => {
        event.preventDefault()

        for (const i in sites) {
            const IDBee2Link = sites[i][Bee2LinkMappingID[actif]]
            if (sitesBee2LinkResult[IDBee2Link] && sitesBee2LinkResult[IDBee2Link] == 'ko') {
                alert("Au moins un ClientID Bee2Link n'est pas reconnu par Bee2Link")
                return false
            }
        }

        setSaving(true)

        fetch(localStorage.backoffice_api + `/api/interfaces/${Bee2LinkMappingURL[actif]}/config`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.JWT,
            },
            body: JSON.stringify({
                sites: sites,
            }),
        }).then((response) => {
            if (!response.ok) {
                response.json().then((value) => {
                    alert(value.message)
                })

                setSaving(false)
                throw Error(response.statusText)
            }
            setSaving(false)
            setSites([])
        }).then(() => {
            fetchConfig(actif)
        }).catch((error) => {
            console.log(error)
        })
    }

    const setSiteActif = (IDSite: any, checked: boolean) => {
        const sitesToUpdate = lodash.cloneDeep(sites)
        const idx = lodash.findIndex(sitesToUpdate, { IDSite: IDSite })
        if (!sitesToUpdate[idx].IDSiteBee2Link) {
            alert("La correspondance avec Bee2Link doit être effectuée pour pouvoir activer le site")
            return false
        }

        sitesToUpdate[idx].Actif = checked
        sitesToUpdate[idx].updated = true

        setSites(sitesToUpdate)
        setUpdated(true)

    }

    const setSiteEstPasserelle = (IDSite: any, checked: boolean) => {
        const sitesToUpdate = lodash.cloneDeep(sites)
        const idx = lodash.findIndex(sitesToUpdate, { IDSite: IDSite })

        sitesToUpdate[idx].EstPasserelle = checked
        sitesToUpdate[idx].updated = true

        setSites(sitesToUpdate)
        setUpdated(true)
    }

    const setClientIDBee2Link = (IDSite: any, IDBee2Link: any) => {
        const sitesToUpdate = lodash.cloneDeep(sites)
        const idx = lodash.findIndex(sitesToUpdate, { IDSite: IDSite })
        sitesToUpdate[idx][Bee2LinkMappingID[actif]] = IDBee2Link
        sitesToUpdate[idx].IDSiteBee2Link = 0
        sitesToUpdate[idx].updated = true
        if (IDBee2Link === "") sitesToUpdate[idx].Actif = false
        else if (sitesToUpdate[idx].Actif === null) sitesToUpdate[idx].Actif = false

        setSites(sitesToUpdate)
        setUpdated(true)
    }

    const setSiteBee2Link = (IDSite: any, IDSiteBee2Link: any) => {
        IDSiteBee2Link = parseInt(IDSiteBee2Link)
        const sitesToUpdate = lodash.cloneDeep(sites)
        const idx = lodash.findIndex(sitesToUpdate, { IDSite: IDSite })
        sitesToUpdate[idx].IDSiteBee2Link = IDSiteBee2Link
        sitesToUpdate[idx].updated = true
        if (IDSiteBee2Link === 0) sitesToUpdate[idx].Actif = false
        else if (sitesToUpdate[idx].Actif === null) sitesToUpdate[idx].Actif = false

        setSites(sitesToUpdate)
        setUpdated(true)
    }

    return (
        <div className="container-fluid mb-4">

            <h2>Interface Bee2Link</h2>
            <ul className="nav nav-tabs" id="myTab">
                <li className="nav-item">
                    <a onClick={() => setActif(Bee2Link.Wafy)} className="nav-link active" id="wafy-tab" data-toggle="tab" href="#wafy" role="tab" aria-controls="wafy" aria-selected="true">Wafy</a>
                </li>
                <li className="nav-item">
                    <a onClick={() => setActif(Bee2Link.OpenFlex)} className="nav-link" id="openflex-tab" data-toggle="tab" href="#openflex" role="tab" aria-controls="openflex"
                       aria-selected="true">Openflex</a>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent" style={{ paddingTop: '15px' }}>
                <div className="alert alert-warning">
                    <i className="fa fa-warning mr-1 mt-2"></i>
                    Attention de ne pas mapper le même "Site" dans la configuration <b>{actif === Bee2Link.Wafy ? Bee2Link.OpenFlex : Bee2Link.Wafy}</b>
                </div>
                <div className="tab-pane fade show active" id="wafy" role="tabpanel" aria-labelledby="wafy-tab">
                    <Wafy
                        loaded={loaded}
                        saving={saving}
                        updated={updated}
                        loadingSitesFromBee2Link={loadingSitesFromBee2Link}
                        sites={sites}
                        sitesBee2Link={sitesBee2Link}
                        sitesBee2LinkResult={sitesBee2LinkResult}
                        submitConfig={submitConfig}
                        setClientIDBee2Link={setClientIDBee2Link}
                        loadSitesBee2Link={loadSitesBee2Link}
                        setSiteEstPasserelle={setSiteEstPasserelle}
                        setSiteBee2Link={setSiteBee2Link}
                        setSiteActif={setSiteActif}
                    />
                </div>
                <div className="tab-pane fade" id="openflex" role="tabpanel" aria-labelledby="openflex-tab">
                    <Openflex
                        loaded={loaded}
                        saving={saving}
                        updated={updated}
                        loadingSitesFromBee2Link={loadingSitesFromBee2Link}
                        sites={sites}
                        sitesBee2Link={sitesBee2Link}
                        sitesBee2LinkResult={sitesBee2LinkResult}
                        submitConfig={submitConfig}
                        setClientIDBee2Link={setClientIDBee2Link}
                        loadSitesBee2Link={loadSitesBee2Link}
                        setSiteEstPasserelle={setSiteEstPasserelle}
                        setSiteBee2Link={setSiteBee2Link}
                        setSiteActif={setSiteActif}
                    />
                </div>
            </div>
        </div>
    )

}
