export class Site
{
    IDSite!: number
    Descr!: number

    constructor(data?: Partial<Site>) {
        Object.assign(this, data)
    }

    getLabel = () => {
        return this.Descr
    }

    getValue = () => {
        return this.IDSite
    }
}