export class SiteWally
{
    IdWallySite!: number
    Nom!: string

    constructor(data?: Partial<SiteWally>) {
        Object.assign(this, data)
    }

    getPrimaryKey = () => {
        return this.IdWallySite
    }
    getTitle = () => {
        return this.Nom
    }

}


