import React, { useState, useEffect } from "react"
import BackOfficeAPIClient from "../../../../clients/BackofficeAPIClient"
import { CompteWally } from "../Types/Class/CompteWally"
import { Link, useHistory, useLocation, useParams } from "react-router-dom"
import { ButtonAction, ButtonAction2 } from "../../../UtilsComponents/ButtonAction"
import { CrmParam } from "../Types/CrmParam"
import HeaderPageWally from "../MappingComponents/HeaderPageWally"



export default function CreationCompte() {
    const backofficeApiClient = new BackOfficeAPIClient()

    const params:CrmParam = useParams<CrmParam>()
    const { search } = useLocation();

    const [ account, setAccount ] = useState<CompteWally>(new CompteWally({ Identifiant: "", MotDePasse: "" }))
    const [ accountExisted, setAccountExisted ] = useState<CompteWally>(new CompteWally({ Identifiant: "", MotDePasse: "" }))
    const [ fetchProgress, setFetchProgress ] = useState<boolean>(false)
    const [ errorLogin, setErrorLogin ] = useState<any>({ color: "inherit", message: "" })
    const [ errorPassword, setErrorPassword ] = useState<any>({ color: "inherit", message: "" })
    const [ errorMain, setErrorMain ] = useState<any>({ color: "inherit", message: "" })
    const history = useHistory();

    useEffect(() => {
        const searchObj = new URLSearchParams(search)
        const localStorageAccount = localStorage.getItem("wally_account_"+ searchObj.get("account_id"));
        if(localStorageAccount !== null){
            setAccountExisted(new CompteWally(JSON.parse(localStorageAccount)))
            setAccount(new CompteWally(JSON.parse(localStorageAccount)))
        }
    }, [])
    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (checkError() > 0) {
            return
        }

        setFetchProgress(true)
        backofficeApiClient
            .post<any>("/interfaces/wally/accounts", { login: account.Identifiant, password: account.MotDePasse, crm_name: params.crm_name })
            .then(r => {
                clearStorage();
                const updatedAccount = {...account, IdWallyCompte:r.IdWallyCompte};
                setAccount(updatedAccount)
                localStorage.setItem("wally_account_"+r.IdWallyCompte, JSON.stringify(updatedAccount));
                history.push("/interfaces/wally/"+params.crm_name+"/comptes/creation/"+r.IdWallyCompte+"/mapping-point-de-vente")
            })
            .catch(e => {
                const errorJSON = JSON.parse(e.message)
                if (errorJSON.message) setErrorMain({ ...errorMain, color: "orange", message: errorJSON.message })
            })
            .finally(() => setFetchProgress(false))

    }

    const checkError = () => {
        let errorCount = 0
        // Prerequis
        if (account.Identifiant === "") {
            setErrorLogin({ ...errorLogin, color: "orange", message: "Veuillez remplir ce champ" })
            errorCount++
        }
        // Prerequis
        if (account.MotDePasse === "") {
            setErrorPassword({ ...errorLogin, color: "orange", message: "Veuillez remplir ce champ" })
            errorCount++
        }

        return errorCount
    }

    const toggleHiddenPassword = () => {
        setAccount({ ...account, isPasswordHidden: !account.isPasswordHidden })
    }

    const clearStorage = () => {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key && key.startsWith("wally_account_")) {
                localStorage.removeItem(key);
            }
        }
    }

    return <>
        {fetchProgress &&
            <div className="loader-container">
                <i className="fas fa-spinner loader"></i>
            </div>
        }
        <div className={"container-fluid mb-2 pr-5 pl-5"}>
            <div className={"row"}>
                <div className={"col"}>
                    <HeaderPageWally title={"Création d'un compte"} />
                </div>
            </div>
            <div className={"row"}>
                <form onSubmit={handleSubmit} className={"w-100"}>

                    <div className={"d-flex flex-column align-items-center m-5 w-100"}>
                        <div className={"flex-grow-1 w-50 mb-4"}>
                            <label htmlFor="login" className={"text-center w-100"} style={{ color: errorLogin.color }}>
                                Identifiant
                            </label>
                            <input type={"text"} className={"form-control form-control-xl p-2 pr-5"} style={{ color: errorLogin.color }}
                                   id={"login"} onChange={(e) => setAccount({ ...account, Identifiant: e.target.value })}
                                   name={"login"} value={account.Identifiant}/>
                            <small style={{ color: errorLogin.color }}>
                                <b>{errorLogin.message} </b>
                            </small>
                        </div>

                        <div className={"flex-grow-1 w-50 mb-4"}>
                            <label htmlFor="password" className={"text-center w-100"} style={{ color: errorPassword.color }}>
                                Mot de passe
                            </label>
                            <div className={"position-relative"}>
                                <input type={account.isPasswordHidden ? "password" : "text"}
                                       className={"form-control form-control-xl p-2 pr-5"}
                                       id={"password"} name={"password"}
                                       value={account.MotDePasse} style={{ color: errorPassword.color }}
                                       onChange={(e) => setAccount({ ...account, MotDePasse: e.target.value })}
                                />

                                <i onClick={toggleHiddenPassword} role="button"
                                   className={"fa-solid p-2 " + (account.isPasswordHidden ? " fa-eye" : " fa-eye-slash")}
                                   style={{
                                       color: "darkgray",
                                       position: "absolute",
                                       right: "10px",
                                       top: "50%",
                                       transform: "translateY(-50%)",
                                       cursor: "pointer",
                                   }}>
                                </i>
                            </div>
                            <small style={{ color: errorPassword.color }}>
                                <b>{errorPassword.message} </b>
                            </small>

                        </div>


                        <h3 className={"mt-3 "} style={{ color: errorMain.color }}>{errorMain.message}</h3>
                    </div>

                    <div className={"d-flex d-inline justify-content-between"}>
                        <ButtonAction href={"/interfaces/wally/" + params.crm_name + "/comptes"} className={"btn btn-danger"}>
                            Annuler
                        </ButtonAction>

                        <div>
                            {accountExisted.IdWallyCompte &&
                                <ButtonAction href={"creation/" + accountExisted.IdWallyCompte + "/mapping-point-de-vente"} className={"btn btn-primary"}>
                                    Continuer avec {accountExisted.Identifiant}
                                </ButtonAction>
                            }
                            <ButtonAction2 className={"btn btn-success ml-2"}>
                            Suivant
                            </ButtonAction2>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </>
}
