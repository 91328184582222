export class MarqueWally
{
    IdWallyMarque!: number
    Nom!: string

    constructor(data?: Partial<MarqueWally>) {
        Object.assign(this, data)
    }

    getPrimaryKey = () => {
        return this.IdWallyMarque
    }
    getTitle = () => {
        return this.Nom
    }

}