import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import Utils from '../../../../Utils'
import _ from "lodash"
import BackOfficeAPIClient from "../../../../clients/BackofficeAPIClient"
import { titleTypes, urlLogoTypes } from "../Types/TitleType"
import { LeadWally } from "../Types/Interface/LeadWally"
import { OffreWally } from "../Types/Interface/OffreWally"
import { ButtonAction } from "../../../UtilsComponents/ButtonAction"
import { CrmParam } from "../Types/CrmParam"
import HeaderPageWally from "../MappingComponents/HeaderPageWally"
declare const window: any

export default function Index() {

    const params: CrmParam = useParams<CrmParam>()
    const backOfficeApiClient = new BackOfficeAPIClient()

    moment.locale('fr')
    const [ leads, setLeads ] = useState<LeadWally[]>([])
    const [ offres, setOffres ] = useState<OffreWally[]>([])
    const [ start_date, setStartDate ] = useState(moment().subtract(2, 'month').startOf('month'))
    const [ end_date, setEndDate ] = useState(moment().endOf('month'))
    const [ fetchInProgress, setFetchInProgress ] = useState(true)
    const [ linkEsellerLead, setLinkEsellerLead ] = useState("")
    const [ urls, setUrls ] = useState<any>([])
    const [ titleType, setTitleType ] = useState("")

    useEffect(() => {
        fetchLinks()
        fetchLeads()
        fetchOffres()
        setTitleType(titleTypes[params.crm_name])
    }, [ end_date ])

    const generateTooltips = () => {
        window.$('[data-toggle="tooltip"]').tooltip()
    }

    const fetchLinks = () => {
        fetch(localStorage.backoffice_api + '/api/bdref3/urls', {
            method: 'GET',
            headers: {
                authorization: localStorage.JWT,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText)
                }
                return response.json()
            })
            .then((json) => {
                const indexLinks = _.findIndex(json, (l: any) => {
                    return l.URL.toLowerCase().search('e-seller.selectup.com') !== -1 || l.URL.toLowerCase().search('smit.selectup.com') !== -1
                })

                if (indexLinks < 0) {
                    return
                }

                let linkEsellerLead = ''
                if (Utils.fromJWT('serverName') === 'PROD-CRM-SQL1' || Utils.fromJWT('serverName') === 'PROD-CRM-SQL2' || Utils.fromJWT('serverName') === 'PROD-CRM-SQL3') {
                    linkEsellerLead = 'http://' + json[indexLinks].URL.replace('e-seller.selectup.com', 'lead.e-seller.selectup.com').replace('smit.selectup.com', 'lead.e-seller.selectup.com')
                } else {
                    linkEsellerLead = 'http://'
                        + json[indexLinks].URL.replace('e-seller.selectup.com', 'dev.lead.e-seller.selectup.com').replace('smit.selectup.com', 'dev.lead.e-seller.selectup.com')
                }

                setLinkEsellerLead(linkEsellerLead)
                setUrls(json)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const fetchLeads = () => {
        setFetchInProgress(true)
        backOfficeApiClient
            .get<LeadWally[]>('/interfaces/wally/lead?start_date=' + start_date.format('YYYY-MM-DD HH:mm:ss') +
                '&end_date=' + end_date.format('YYYY-MM-DD HH:mm:ss') + `&crm_name=${params.crm_name}`)
            .then((r: LeadWally[]) => {
                setLeads(r)
                setFetchInProgress(false)
                setTimeout(() => { generateTooltips() }, 350)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const fetchOffres = () => {
        setFetchInProgress(true)
        backOfficeApiClient
            .get<OffreWally[]>('/interfaces/wally/offres?start_date=' +
                start_date.format('YYYY-MM-DD HH:mm:ss') + '&end_date=' +
                end_date.format('YYYY-MM-DD HH:mm:ss') +
                `&crm_name=${params.crm_name}`)
            .then((r: OffreWally[]) => {
                setOffres(r)
                setFetchInProgress(false)

                setTimeout(() => { generateTooltips() }, 350)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const generateLine = (affaire: LeadWally) => {

        return (<tr key={affaire.CodeAffaireSource}>
            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><a href={getUrlOngletConstructeur(affaire.CodeAffaireSource, affaire.Comment)}
                                                                            target="_blank"> <img src={urlLogoTypes[affaire.Constructeur]} style={{ maxHeight: '30px' }}/></a></td>
            <td style={{ verticalAlign: 'middle' }}>{moment(affaire.DateDebut).utc().format('DD/MM/YYYY HH:mm')}</td>
            <td style={{ verticalAlign: 'middle' }}>{affaire.Site}</td>
            <td style={{ verticalAlign: 'middle' }}>{affaire.Origine}</td>
            <td style={{ verticalAlign: 'middle' }}>
                <a className="mr-2" href={linkEsellerLead + '/prospect/' + affaire.IDClient} target="_blank">
                    <i className="fa fa-external-link " aria-hidden="true"></i>
                </a>
                <a className="mr-2" href={linkEsellerLead.replace('lead.e-seller.selectup.com', 'crm.edatavoice.com') + '/customers/' + affaire.IDClient} target="_blank">
                    <i className="fa fa-external-link " aria-hidden="true"></i>
                </a>
                {affaire.Client_name} {affaire.Client_phone} {affaire.Client_email}
            </td>
            <td style={{ verticalAlign: 'middle' }}>{affaire.Statut}</td>
            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                <i className="fa fa-search " data-toggle="tooltip" data-trigger="hover" data-placement="left" data-html="true"
                   title={affaire.Comment.replaceAll('\n', '<br/>')}>
                </i>
            </td>
        </tr>)
    }

    const generateLineOffre = (offre: OffreWally) => {

        return (<tr key={offre.IDOffres}>
            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}><a href={offre.UrlExterne} target="_blank"><img src={urlLogoTypes[offre.Constructeur]} style={{ maxHeight: '30px' }}/></a>
            </td>
            <td style={{ verticalAlign: 'middle' }}>{moment(offre.Date).utc().format('DD/MM/YYYY HH:mm')}</td>
            <td style={{ verticalAlign: 'middle' }}>
                <a className="mr-2" href={linkEsellerLead.replace('lead.e-seller.selectup.com', 'crm.edatavoice.com') + '/offers/' + offre.IDClient} target="_blank">
                    <i className="fa fa-external-link " aria-hidden="true"></i>
                </a>
                {offre.FichierPDF ?
                    <a className="mr-2" href={'https://pdf.e-seller.selectup.com/pdf/' + urls[0].NomGP + '/' + offre.FichierPDF} target="_blank">
                        <i className="fa fa-file-pdf-o " aria-hidden="true"></i>
                    </a>
                    : null}
                {offre.IDOffres}
            </td>
            <td style={{ verticalAlign: 'middle' }}>{offre.Site}</td>
            <td style={{ verticalAlign: 'middle' }}>{offre.Origine}</td>
            <td style={{ verticalAlign: 'middle' }}>
                <a className="mr-2" href={linkEsellerLead + '/prospect/' + offre.IDClient} target="_blank">
                    <i className="fa fa-external-link " aria-hidden="true"></i>
                </a>
                <a className="mr-2" href={linkEsellerLead.replace('lead.e-seller.selectup.com', 'crm.edatavoice.com') + '/customers/' + offre.IDClient} target="_blank">
                    <i className="fa fa-external-link " aria-hidden="true"></i>
                </a>
                {offre.Client_name} {offre.Client_phone} {offre.Client_email}
            </td>
            <td style={{ verticalAlign: 'middle' }}>{offre.Statut}</td>
            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                <i className="fa fa-search " data-toggle="tooltip" data-trigger="hover" data-placement="left" data-html="true"
                   title={offre.Comment.replaceAll('\n', '<br/>')}>
                </i>
            </td>
        </tr>)
    }

    const addMonth = () => {
        if (fetchInProgress) return
        setStartDate(moment(start_date).add(1, 'month').startOf('month'))
        setEndDate(moment(start_date).add(1, 'month').endOf('month'))
    }

    const subMonth = () => {
        if (fetchInProgress) return
        setStartDate(moment(start_date).subtract(1, 'month').startOf('month'))
        setEndDate(moment(start_date).subtract(1, 'month').endOf('month'))
    }

    const getUrlOngletConstructeur = (codeAffaire: string, commentaire: string) => {

        let url = ""
        const splitComment = commentaire.split('\n')

        splitComment.forEach((part) => {
            if (part.includes("http")) {
                url = part
            }
        })

        if (codeAffaire && !codeAffaire.startsWith("HMF")) {
            if (codeAffaire.includes("|OP-")) {
                const items = codeAffaire.split("|OP-")
                url = `https://www.customer360psa.com/s/opportunity/${items[1]}`
            }
        }

        return url

    }

    if (fetchInProgress) return null

    return <>
        <div className="container-fluid mb-2 pr-5 pl-5">
            <div className="row">
                <div>
                    <ButtonAction href={"/interfaces/wally/"} className={"btn btn-secondary"}>
                        <i className={"fa-solid fa-chevron-left fa-xs"}></i>
                    </ButtonAction>
                </div>
                <div className="col">
                    <HeaderPageWally title={"Historique d'intégration " + titleType}/>

                    <ul className="nav nav-tabs" id="myTab">
                        <li className="nav-item">
                            <a className="nav-link active" id="logs-tab" data-toggle="tab" href="#logs" role="tab" aria-controls="logs" aria-selected="true">Leads</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="configuration-tab" data-toggle="tab" href="#configuration" role="tab" aria-controls="configuration" aria-selected="true">Offres</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent" style={{ paddingTop: '15px' }}>
                        <div className="tab-pane fade show active" id="logs" role="tabpanel" aria-labelledby="logs-tab">
                            <div>
                                <br/>

                                <div className="row" style={{ marginBottom: '15px' }}>
                                    <div className="col-md-4 text-right">
                                        <a onClick={subMonth} role="button">
                                            <i className="fa fa-chevron-left"></i>
                                            <i className="fa fa-chevron-left"></i>
                                        </a>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        Du <b>{start_date.format('DD/MM/YYYY')}</b> au <b>{end_date.format('DD/MM/YYYY')}</b>
                                    </div>
                                    <div className="col-md-4 text-left">
                                        <a onClick={addMonth} role="button">
                                            <i className="fa fa-chevron-right"></i>
                                            <i className="fa fa-chevron-right"></i>
                                        </a>
                                    </div>
                                </div>

                                <table className="table table-bordered table-sm" style={{ 'fontSize': '11px' }}>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Date début affaire</th>
                                        <th>Site</th>
                                        <th>Origine</th>
                                        <th>Prospect</th>
                                        <th>Statut</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {leads && leads.length > 0 && leads.map((affaire) => {
                                        const content = []
                                        content.push(generateLine(affaire))
                                        return content
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="tab-pane fade " id="configuration" role="tabpanel" aria-labelledby="configuration-tab">
                            <div>
                                <br/>
                                <div className="row" style={{ marginBottom: '15px' }}>
                                    <div className="col-md-4 text-right">
                                        <a onClick={subMonth} role="button">
                                            <i className="fa fa-chevron-left"></i>
                                            <i className="fa fa-chevron-left"></i>
                                        </a>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        Du <b>{start_date.format('DD/MM/YYYY')}</b> au <b>{end_date.format('DD/MM/YYYY')}</b>
                                    </div>
                                    <div className="col-md-4 text-left">
                                        <a onClick={addMonth} role="button">
                                            <i className="fa fa-chevron-right"></i>
                                            <i className="fa fa-chevron-right"></i>
                                        </a>
                                    </div>
                                </div>

                                <table className="table table-bordered table-sm" style={{ 'fontSize': '11px' }}>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Date</th>
                                        <th>IDOffres</th>
                                        <th>Site</th>
                                        <th>Origine</th>
                                        <th>Prospect</th>
                                        <th>Statut</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {offres && offres.length && offres.map((offre) => {
                                        const content = []
                                        content.push(generateLineOffre(offre))
                                        return content
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>
}


