export class OrigineWally
{
    IdWallyOrigine!: number
    Nom!: string

    constructor(data?: Partial<OrigineWally>) {
        Object.assign(this, data)
    }

    getPrimaryKey = () => {
        return this.IdWallyOrigine
    }
    getTitle = () => {
        return this.Nom
    }
}