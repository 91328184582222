import React from 'react'

type Props = {
    loaded: boolean,
    saving: boolean
    updated: boolean
    loadingSitesFromBee2Link: boolean
    sites: any[]
    sitesBee2Link: any[]
    sitesBee2LinkResult: any[]
    submitConfig: (event: any) => void
    setClientIDBee2Link: (IDSite: any, IDBee2Link: any) => void
    loadSitesBee2Link: (IDBee2Link: any) => void
    setSiteActif: (IDSite: any, checked: boolean) => void
    setSiteEstPasserelle: (IDSite: any, checked: boolean) => void
    setSiteBee2Link: (IDSite: any, IDSiteBee2Link: any) => void
}
export default function Wafy(props: Props) {

    if (!props.loaded) {
        return null
    }

    return (
        <form onSubmit={(e) => props.submitConfig(e)}>
            <div className="row">
                <div className="col mt-4 mb-2">
                    <table className="table table-striped table-sm">
                        <thead>
                        <tr>
                            <th>Société</th>
                            <th>Site</th>
                            <th>ClientID Bee2Link</th>
                            <th>Site Bee2Link</th>
                            <th className="text-center">Est Passerelle</th>
                            <th className="text-center">Actif</th>
                        </tr>
                        </thead>
                        <tbody>
                        {props.sites.map((site) => {
                            return (
                                <tr key={site.IDSite}>
                                    <td>{site.Libelle_Societe}</td>
                                    <td>{site.Descr}</td>
                                    <td className="d-flex align-items-center">
                                        <input
                                            className="form-control w-75 d-inline-block"
                                            type="text"
                                            placeholder=""
                                            value={site.ClientIDBee2Link ? site.ClientIDBee2Link : ''}
                                            onChange={(e) => props.setClientIDBee2Link(site.IDSite, e.target.value)}
                                            onBlur={() => props.loadSitesBee2Link(site.ClientIDBee2Link)}/>
                                        {
                                            props.sitesBee2LinkResult[site.ClientIDBee2Link] ?
                                                props.sitesBee2LinkResult[site.ClientIDBee2Link] === 'loading' ?
                                                    <i className="fa fa-refresh fa-spin" style={{ fontSize: '1.5em' }}></i>
                                                    : props.sitesBee2LinkResult[site.ClientIDBee2Link] === 'ok' ?
                                                        <i className="fa fa-check-circle text-success" style={{ fontSize: '1.5em' }}></i>
                                                        : props.sitesBee2LinkResult[site.ClientIDBee2Link] === 'ko' ?
                                                            <i className="fa fa-times text-danger" style={{ fontSize: '1.5em' }}></i>
                                                            : null
                                                : null
                                        }
                                    </td>
                                    <td style={{ width: '1px' }}>
                                        <select
                                            className="form-control"
                                            onChange={(e) => props.setSiteBee2Link(site.IDSite, e.target.value)}
                                            value={site.IDSiteBee2Link ? site.IDSiteBee2Link : ''}
                                            style={{ width: '400px' }}
                                        >
                                            <option value={0}></option>
                                            {props.sitesBee2Link[site.ClientIDBee2Link] ?
                                                props.sitesBee2Link[site.ClientIDBee2Link].map((sb2l: any) => {
                                                    return <option key={"sb2l" + sb2l.id} value={sb2l.id}>{sb2l.id} - {sb2l.libelle}</option>
                                                })
                                                : null
                                            }
                                        </select>
                                    </td>
                                    <td className="text-center">
                                        <input
                                            className="mt-1"
                                            type="checkbox"
                                            checked={site.EstPasserelle === true}
                                            onChange={(e) => props.setSiteEstPasserelle(site.IDSite, e.target.checked)}/>
                                    </td>
                                    <td
                                        className="text-center">
                                        <input
                                            className="mt-1"
                                            type="checkbox"
                                            checked={site.Actif === true}
                                            onChange={(e) => props.setSiteActif(site.IDSite, e.target.checked)}/>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>

            {props.updated ?
                <div className="row mb-2" style={{ marginTop: '15px' }}>
                    <div className="col text-center">
                        <button disabled={props.saving || props.loadingSitesFromBee2Link} type="submit" className="btn btn-primary">
                            {props.saving ?
                                <span><i className="fa fa-spinner fa-spin fa-fw"></i>&nbsp;</span>
                                : null}
                            Enregistrer
                        </button>
                    </div>
                </div>
                : null}
        </form>
    )
}